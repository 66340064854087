<template>
  <div id="body">
  <!-- 头部 -->
    <div class="page-header">
      <!-- 导航 -->
      <TopBar class="navi" :activeIndex="'3'"/>
      <!-- bannner -->
      <CBanner :bannerCont="bannerCont"/>
    </div>
    <div class="page-container">
      <!-- 左侧导航 -->
      <div class="page-body">
        <div class="r-menu">
          <div class="menu-title">
            <h5>产品中心</h5>
          </div>
          <div class="menu-content">
            <Pmenu :url="url" :menuList="menuList"/>
          </div>
        </div>
        <div class="l-content">
          <router-view :key="$route.fullPath"></router-view>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
// 导航
import TopBar from './common/TopBar.vue'
// banner
import CBanner from './common/content/CBanner.vue'
// 手风琴导航
import Pmenu from './common/content/product/Pmenu.vue'
// footer
import Footer from './common/Footer.vue'

export default {
  name: "Atlas",
  components: {
    'TopBar': TopBar,
    'CBanner': CBanner,
    'Pmenu': Pmenu,
    'Footer': Footer
  },
  data() {
    return {
      bannerCont: {
        pic: require('../../assets/product-banner.png'),
        title: "多功能核数据处理软件Atlas-C",
        txt: "针对核能领域的不同需求，自主研发核心产品并提供各类技术服务和咨询服务\nAtlas系列，Bamboo系列，SARAX系列，MCX系列等；核电厂换料校核计算、调试技术支持、运行技术改造等",
      },
      menuList: Array,
      url: '/atlas/'
    }
  },
  created() {
    this.menuList = this.$store.state.atlasList;
  },
  methods: {

  }
}
</script>

<style scoped>
div#body {
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
}
div.page-header {
  position: relative;
  top: 0;
  max-width: 1920px;
  margin: 0 auto;
}
/* 导航 */
.navi {
  position: absolute;
  width: 100%;
  z-index: 200;
}
/* 主体 */
div.page-container {
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  margin-bottom: 3rem;
}
div.page-container::after {
  content: "";
	clear: both;
	content: '';
	display: block;
	width: 0;
	height: 0;
	visibility: hidden;
}
div.page-container > div.page-body {
  max-width: 1280px;
  margin: 1.5rem auto;
}
div.r-menu {
  width: 280px;
  float: left;
  background: #199be9;
}
div.r-menu > div.menu-title > h5 {
  height: 5.625rem;
  line-height: 5.625rem;
  font-size: 1.5rem;
  color: #fff;
  font-weight: normal;
  text-align: center;
}
div.r-menu > div.menu-content {
  padding-left: 0.5rem;
}
div.l-content {
  width: calc(100% - 330px);
  float: right;
}
</style>